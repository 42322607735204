.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    z-index: 9999;
    /*position: relative;*/
    position: fixed;
    top:0;
    left:0;
    right:0;
}

.navbar-nav {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-item {
    margin: 0 2vw;
    transition: 300ms ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    color:var(--base-white-100)
}

.nav-item:hover {
    color: var(--base-white-100);
    /*transform:scale(1.05);*/
}

.nav-item div {
    height: 3px;
    transition: 300ms ease-in-out;
    width: 0;
}

.nav-item:hover div {
    height: 3px;
    background-color: var(--base-white-100);
    width: 100%;
}

.nav-item-dark {
    margin: 0 3vw;
    transition: 300ms ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
}

.nav-item-dark:hover {
    color: var(--base-white-100);
    transform:scale(1.05);
}

.nav-item-dark div {
    height: 3px;
    transition: 300ms ease-in-out;
    width: 0;
}

.nav-item-dark:hover div {
    height: 3px;
    background-color: var(--base-white-100);
    width: 100%;
}

.navbar-section-left {
    padding-left: 5vw;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar-section-middle {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-left: 1px solid var(--base-black-20);
    border-right: 1px solid var(--base-black-20);
}

.navbar-section-right {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5vw;
}

.nav-item a {
    color: #333;
    text-decoration: none;
    font-size: 1.2rem;
}

.nav-item a:hover {
    text-decoration: underline;
}

.navbar-item {
    display: inline-block;
    padding: 10px;
    font-weight: normal;
    transition: all 0.3s ease-in-out;
}

.navbar-item.hovered {
    border-bottom: 2px solid #333;
}

.navbar-item.active {
    font-weight: bold;
}

.flip-container {
    display: inline-block;
    perspective: 1000px;
}

.flipper {
    display: inline-block;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.3s ease-in-out;
}

.front,
.back {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.front {
    z-index: 2;
}

.back {
    transform: rotateY(180deg);
}
