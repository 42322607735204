.post-feed {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*padding-top: 32px;*/
}

.post {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: center;*/
    /*margin-bottom: 32px;*/
    position: relative;
    outline: 2px solid transparent;
    border-radius: 32px;
    outline-offset: 4px;
}

.post {
    transition: 150ms ease-in-out;
}

.post-hovering {
    transform: translateY(-4px) scale(1.015);
    outline: 2px solid var(--primary);
    border-radius: 32px;
    outline-offset: 4px;
}

.shadow {
    box-shadow: 0 12px 20px #60FEEF07;
}

.video {
    width: inherit !important;
    border-radius: 24px;
    overflow: hidden;
}

.post img {
    box-shadow: 0 12px 20px #60FEEF07;
}

.post-cover-gradient {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 3px;
    background: linear-gradient(20deg, black, #00000000);
    z-index: 1;
}

.post-info {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    z-index: 3;
}

.post-nft-banner {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    z-index: 3;
    background-color: var(--base-white-30);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    padding: 4px 6px
}

.grid-container {
    display: grid;
    padding: var(--page-h-padding-mobile);
}

.grid-item {
    border-width: 0;
    outline: none;
    background-color: var(--base-white-4);
}

@media only screen and (min-width: 1325px) {
    .grid-container {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 44px 32px;
    }

    .grid-item:nth-child(odd) {
        margin-top: 12px;
    }
}

@media only screen and (max-width: 1324px) and (min-width: 800px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 44px 32px;
    }

    .grid-item:nth-child(2n) {
        margin-top: 24px;
    }
}

@media only screen and (max-width: 799px) and (min-width: 499px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 44px 32px;
    }

    .grid-item:nth-child(2n) {
        margin-top: 24px;
    }
}

@media only screen and (max-width: 500px) {
    .grid-container {
        grid-template-columns: 1fr;
        grid-gap: 32px;
    }

    .grid-item {
        margin-top: 0;
    }
}

.fade-in-on-scroll {
    opacity: 0;
    transition: all 400ms ease-in-out;
    transform: translateY(40px) scale(.8);
}

.fade-in-on-scroll.visible {
    opacity: 1;
    transform: translateY(0px) scale(1);
}

.wallet-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: 150ms ease;
    border: 1px solid transparent;
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 8px;
}

.wallet-option:hover {
    color: #6095FEFF;
    border: 1px solid var(--primary);
    transform: translateY(-2px);
}

.wallet-option:hover p {
    color: var(--primary);
}

input {
    background-color: #ffffff20;
    border: none !important;
    outline: none !important;
    font-size: 16px;
    padding: 12px 20px;
    border-radius: 12px;
    color: var(--base-white-100)
}

.react-select__control {
    border-radius: 1000px !important;
    padding-left:12px;
    padding-right: 12px;
    height: 48px;
}

.react-select__menu {
    border-radius: 20px !important;
    overflow: hidden;
}
